// import app from "./firebaseConfig";
import { app, auth, signOut, onAuthStateChanged } from "./firebase.js";
import { page, spinner } from "./utilities.js";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
const btnLogout = document.querySelector("#logout");
const btnLogin = document.querySelector("#login");
const btnRegister = document.querySelector("#register");
const btnProfile = document.querySelector("#profile");
const btnFaq = document.querySelector("#faq");
const btnSupport = document.querySelector("#supportOption");
var usersUid = "";
var memberProgress = "";
const db = getFirestore(app);
const storage = getStorage(app);

export {
  auth,
  signOut,
  onAuthStateChanged,
  db,
  storage,
  page,
  spinner,
  getDoc,
};
/************* TOP NAVIGATION ****************/
if (page.startsWith("/missing/")) {
    /* Logout Tab */
    if (btnLogout) {
      btnLogout.addEventListener("click", (e) => {
        e.preventDefault();
        logoutAccount();
      });
    }

    /* Login Button */
    if (btnLogin) {
      btnLogin.addEventListener("click", (e) => {
        e.preventDefault();
        loginButton();
      });
    }

    /* Register Button */
    if (btnRegister) {
      btnRegister.addEventListener("click", (e) => {
        e.preventDefault();
        registerButton();
      });
    }

    /* Profile Button */
    if (btnProfile) {
      btnProfile.addEventListener("click", (e) => {
        e.preventDefault();
        profileButton();
      });
    }

    /* Support Button */
    if (btnSupport) {
      btnSupport.addEventListener("click", (e) => {
        e.preventDefault();
        supportButton();
      });
    }

    /* FAQs Button */
    if (btnFaq) {
      btnFaq.addEventListener("click", (e) => {
        e.preventDefault();
        faqButton();
      });
    }

}

function memberProfileLookup(uid) {
  return new Promise((resolve, reject) => {
    getDoc(doc(db, "accounts", uid)).then((docSnap) => {
      if (docSnap.exists()) {
        const docdata = docSnap.data();
        const userName = docdata.uid;
        memberProgress = docdata.memberProgress;
        console.log(docdata);
        console.log(memberProgress + "HERE");
        resolve();
      } else {
        reject(new Error("User not found"));
      }
    });
  });
}

function loginButton() {
  location = "/missing/login.html";
}

function registerButton() {
  location = "/missing/register.html";
}

function supportButton() {
  location = "/contact/";
}

function faqButton() {
  location = "/faq/";
}

function lookUpMember(auth) {
  return new Promise((resolve, reject) => {
    const unSubAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        usersUid = user.uid;
        const memberName = user.displayName;
        const memberUid = user.uid;
        console.log("onAuthStateChanged");
        console.log(memberName + " - " + usersUid);
        memberProfileLookup(user.uid)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("User is not authenticated"));
      }
    });
  });
}

function profileButton() {
  lookUpMember(auth)
    .then(() => {
      console.log("User is authenticated");
      console.log("profileButton" + memberProgress);
      // Perform actions that require authentication
      switch (memberProgress) {
        case "Submission":
          // Functionality
          console.log(
            "Your current progress is " +
              memberProgress +
              " so go to the post.html"
          );
          location = "/missing/post.html";
          break;
        case "Complete":
          // Functionality
          location = "/missing/profile.html";
          break;
        default:
          // Functionality
          location = "/missing/register.html";
          // console.log("IN DEFAULT SWITCH");
          break;
      }
    })
    .catch((error) => {
      console.log(error);
      console.log("User is not authenticated");
      // Show a login form or other UI that allows the user to log in
    });
}

export function logoutAccount() {
  // $.LoadingOverlay("show");
  console.log("in logoutAccount");
  $("#loading-spinner").show();
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      console.log("Sign-out successful");
      destroySession();
      console.log("destroySession");
      setTimeout(function () {
        // CREATE PHP SESSION
        console.log("logout successful");
        setTimeout(function () {
          $("#loading-spinner").hide();
          location = "/missing/";
        }, 1000);
      }, 1000);
    })
    .catch((error) => {
      //console.log("logout error");
    });
}

export function destroySession() {
  $.ajax({
    type: "POST",
    url: "/missing/functions/userAccounts.php",
    data: "action=unsetsession",
    success: function (msg) {
      if (msg == "success") {
        console.log("PHP user session deleted");
      } else {
        console.log("PHP user session delete failed");
      }
    },
    error: function (msg) {
      //alert("Error: cannot load page.");
    },
  });
}
