import "js-loading-overlay";
export const spinner = document.getElementById("loading-spinner");
export const page = window.location.pathname;
export function formatDate(dateString) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateObject = new Date(dateString);
  const day = dateObject.getDate();
  const monthIndex = dateObject.getMonth();
  const year = dateObject.getFullYear();

  return `${months[monthIndex]} ${day}, ${year}`;
}

export function capitalizeFirstLetter(str) {
  // Convert the entire string to lowercase first.
  const lowerCasedStr = str.toLowerCase();

  // Then capitalize the first letter of each word.
  return lowerCasedStr.replace(/\b\w/g, function (l) {
    return l.toUpperCase();
  });
}

export function formatPhoneNumber(phoneNumber) {
  phoneNumber = phoneNumber.replace(/\D/g, ""); // Remove all non-digit characters

  if (phoneNumber.length > 0) {
    phoneNumber = phoneNumber.replace(
      /(\d{3})(\d{0,3})(\d{0,4}).*/,
      "($1) $2-$3"
    ); // Apply formatting
  }

  return phoneNumber;
}

export function formatPetNameForURL(petName) {
  // Convert to lowercase
  let formattedName = petName.toLowerCase();

  // Remove leading and trailing whitespace
  formattedName = formattedName.trim();

  // Replace spaces with hyphens
  formattedName = formattedName.replace(/\s+/g, "-");

  // Remove special characters
  formattedName = formattedName.replace(/[^\w-]/g, "");

  return formattedName;
}

export function showCustomLoadingOverlay() {
  JsLoadingOverlay.show({
    'overlayBackgroundColor': '#FFF',
    'overlayOpacity': 0.9,
    'spinnerIcon': 'ball-atom',
    'spinnerColor': '#555',
    'spinnerSize': '2x',
    'overlayIDName': 'overlay',
    'spinnerIDName': 'spinner',
  });
}

export function hideCustomLoadingOverlay() {
  JsLoadingOverlay.hide();
}

export function showCustomLoadingOverlayMain() {
  JsLoadingOverlay.show({
    'overlayBackgroundColor': '#FFF',
    'overlayOpacity': 0.9,
    'spinnerIcon': 'ball-atom',
    'spinnerColor': '#555',
    'spinnerSize': '2x',
    'overlayIDName': 'overlay',
    'spinnerIDName': 'spinner',
  });
}

export function hideCustomLoadingOverlayMain() {
  JsLoadingOverlay.hide();
}


export function goToAccount(action) {
  location = `/missing/account.html?action=${action}`;
}


export function goToPostRenew() {
  location = `/missing/account-renewal.html`;
}

export function goToAccountSupport(usersId) {
  location = `/missing/support/?uid=${usersId}`;
}

export function goToUpgrade() {
  location = `/missing/upgrade.html`;
}
